import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _418b94d2 = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _34bb2435 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _59251ad2 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _42619cb8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3377d052 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _1b9b649f = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _b2544774 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1304592e = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _7f70df62 = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _d1cec896 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _832ae150 = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _d497fae6 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _329edfae = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _4ab15426 = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _42cb97b9 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _72d10ff6 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _3cad8cb0 = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _224550db = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _b2600e04 = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _fce06a20 = () => interopDefault(import('../pages/top-locations/liget-budapest-visitor-center.vue' /* webpackChunkName: "pages/top-locations/liget-budapest-visitor-center" */))
const _01c897e8 = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _32732f95 = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _f7188c18 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _c5e6550e = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _14fb17da = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _530c5ab1 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _38177b99 = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _09322a3e = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _b70380ca = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _ec98cf4c = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _08729782 = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _a5a47be8 = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _4343ce56 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _d2b0c0c0 = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _339871a4 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _6d4bbc9c = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _0de9667d = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _3dbf8137 = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _2459bc0a = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _1990d4e6 = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _45cedf48 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _418b94d2,
    name: "the-project___hu"
  }, {
    path: "/adatvedelem",
    component: _34bb2435,
    name: "privacy-policy___hu"
  }, {
    path: "/archivum",
    component: _59251ad2,
    name: "archive___hu"
  }, {
    path: "/en",
    component: _42619cb8,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _3377d052,
    name: "newsletter___hu"
  }, {
    path: "/karrier",
    component: _1b9b649f,
    name: "career___hu"
  }, {
    path: "/kereses",
    component: _b2544774,
    name: "search___hu"
  }, {
    path: "/kozerdeku-adatok",
    component: _1304592e,
    name: "public-information___hu"
  }, {
    path: "/latogatas",
    component: _7f70df62,
    name: "visitor-information___hu"
  }, {
    path: "/magazin",
    component: _d1cec896,
    name: "magazine___hu"
  }, {
    path: "/sajto",
    component: _832ae150,
    name: "press___hu"
  }, {
    path: "/szakmai-anyagok",
    component: _d497fae6,
    name: "documents___hu"
  }, {
    path: "/terkep",
    component: _329edfae,
    name: "map___hu"
  }, {
    path: "/uzleti-partnereinknek",
    component: _4ab15426,
    name: "business-partners___hu"
  }, {
    path: "/varosligeti-programok",
    component: _42cb97b9,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/en/archive",
    component: _59251ad2,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _4ab15426,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _1b9b649f,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _3377d052,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _1304592e,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _d1cec896,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _329edfae,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _832ae150,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _34bb2435,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _42cb97b9,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _b2544774,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _d497fae6,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _418b94d2,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _7f70df62,
    name: "visitor-information___en"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _72d10ff6,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/projekt/swiper",
    component: _3cad8cb0,
    name: "the-project-swiper___hu"
  }, {
    path: "/sajto/mediatar",
    component: _224550db,
    name: "press-media-library___hu"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _b2600e04,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/top-helyek/liget-budapest-latogatokozpont",
    component: _fce06a20,
    name: "top-locations-liget-budapest-visitor-center___hu"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _01c897e8,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _32732f95,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _f7188c18,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _c5e6550e,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _14fb17da,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _530c5ab1,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _38177b99,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _09322a3e,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media",
    component: _224550db,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _72d10ff6,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _3cad8cb0,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _b2600e04,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _01c897e8,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _32732f95,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _f7188c18,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/liget-budapest-visitor-center",
    component: _fce06a20,
    name: "top-locations-liget-budapest-visitor-center___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _c5e6550e,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _14fb17da,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _530c5ab1,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _38177b99,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _09322a3e,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _b70380ca,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _ec98cf4c,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/en/archive/:article",
    component: _08729782,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _a5a47be8,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _4343ce56,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _d2b0c0c0,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _339871a4,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _6d4bbc9c,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _0de9667d,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _3dbf8137,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _2459bc0a,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _1990d4e6,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _b70380ca,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _ec98cf4c,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/archivum/:article",
    component: _08729782,
    name: "archive-article___hu"
  }, {
    path: "/en/:slug",
    component: _45cedf48,
    name: "slug___en"
  }, {
    path: "/karrier/:position",
    component: _4343ce56,
    name: "career-position___hu"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _d2b0c0c0,
    name: "public-information-data___hu"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _1990d4e6,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/magazin/:article",
    component: _339871a4,
    name: "magazine-article___hu"
  }, {
    path: "/programok/:program",
    component: _3dbf8137,
    name: "programs-program___hu"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _0de9667d,
    name: "program-series-programSeries___hu"
  }, {
    path: "/projekt/:project",
    component: _2459bc0a,
    name: "the-project-project___hu"
  }, {
    path: "/sajto/:release",
    component: _6d4bbc9c,
    name: "press-release___hu"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _a5a47be8,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/",
    component: _42619cb8,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _45cedf48,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
